<template>
  <Html :lang="head?.htmlAttrs?.lang" :dir="head?.htmlAttrs?.dir">
    <div
      id="layout"
      class="isolate grid min-h-dvh grid-rows-[auto,minmax(0,1fr),auto]"
    >
      <PageHeader
        class="bg-header bleed-header skin:bleed-none bleed-bg sticky top-0 z-50 shadow-lg print:static"
      />

      <main id="main" class="flex flex-col">
        <MessageController />
        <slot />

        <ErrorButton />
      </main>

      <PageFooter class="print:hidden" />
    </div>
  </Html>
</template>

<script setup lang="ts">
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

defineOptions({
  name: 'DefaultLayout',
})
</script>
